<template>
  <div class="content-container">
    <PageTitle :title="$t('CLASSES')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t('CREATE_CLASS.NEW') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="ROUTE_NAMES_HELPDESK.CLASSES_OVERVIEW"/>

    <!-- Form -->
    <FormWrapper :send-form-data="validateData" class="page-form">
      <template #form-content>

        <!-- Name -->
        <InputClassName
          :on-input="nameUpdated"
          :accepted="(value) => nameAvailable = value" />

        <!-- Total Students & Group -->
        <div class="two-column-wrapper students-group">
          <!-- Total Students -->
          <InputField :field-title="$t('TOTAL_STUDENTS')"
                      :field-name="'studentCount'"
                      :rules="'required'"
                      :min="0"
                      :max="40"
                      :type="'number'"
                      :placeholder="$t('TOTAL_STUDENTS')"
                      :cy-selector="'students-input'"
                      :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                      :api-error-text="''"
                      class="form-element-column"/>

          <!-- Accessability Type -->
          <Dropdown id="accessibilityType"
                    :field-title="$t('EDIT_CLASS.ASSISTANCE')"
                    :dropdown-options="accessibilityOptions"
                    :placeholder="$t('ASSISTANCE.REGULAR')"
                    :field-name="'accessibilityType'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :callback="accessibilityTypeUpdate"
                    :selected-option="selectedAccessibilityType"
                    :api-error-text="''"
                    :input-icon="require('../../../assets/icons/icn_gebarentaal.svg')"
                    class="form-element-column"/>
        </div>

        <!-- Sound settings-->
        <div class="toggles-container">
         <ToggleSwitch :field-title="'Extra geluiden in de MediaMissies'"
                        :field-name="'hasExtraSounds'"
                        :value="hasExtraSounds"
                        :callback="onSoundsToggle"/>
        </div>

        <!-- School -->
        <Search
          :search-title="$t('SEARCH.INPUT_TITLE')"
          :placeholder="selectedSchool.name ? selectedSchool.name : $t('SEARCH.PLACEHOLDER')"
          :search-id="'search2'"
          :value="schoolSearchValue"
          :manage-search="manageSearch"
          :clear-search="clearSearch"
          :search-results="searchResults"
          class="search-menu"
          :clear-on-focus="true"
          :placeholder-class="selectedSchool.name ? 'selected-placeholder' : ''"
        >
          <!-- Result indicator -->
          <template #content-below="searchedTerm">
            <p v-if="searchedTerm && searchResults && !selectedSchool.name" class="result-indicator">
              {{ searchResults.length }} {{ $t('RESULTS') }}
            </p>
          </template>
        </Search>
        <div v-if="noSchoolSelected" class="no-school-selected" > Geen school geselecteerd  </div>
        <!-- Search results -->
        <SearchResults
          :search-results="searchResults"
          :on-item-click="onItemClick"
        />

        <!-- Competition type -->
        <div class="field-wrapper">
          <Dropdown id="group"
                    :field-title="$t('EDIT_CLASS.COMPETITION_TYPE')"
                    :dropdown-options="groups"
                    :placeholder="$t('EDIT_CLASS.SELECT_COMPETITION_TYPE')"
                    :field-name="'classTypeName'"
                    :selected-option="selectedGroup"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :disabled="!selectedSchool.id"
                    :callback="() => {}"
                    :api-error-text="''"
                    @selected="selectedGroup = $event"
                    class="form-element"/>
          <div class="info-button-wrapper" @click="isCompetitionInfoShown = !isCompetitionInfoShown">
            <img src="@/assets/icons/icn_info.svg" alt="info" class="info-button">
          </div>
          <!-- Info popup -->
          <div class="info-popup" :class="{'shown': isCompetitionInfoShown}">
            <p class="info-popup-text">{{ $t('EDIT_CLASS.COMPETITION_INFO') }}</p>
            <div class="triangle" />
          </div>
        </div>

        <!-- Teacher -->
        <DropdownWithCheckboxes v-if="teacherOptions.length"
                                id="teachers"
                                :field-title="$t('EDIT_CLASS.TEACHERS')"
                                :dropdown-options="teacherOptions"
                                :placeholder="$t('EDIT_CLASS.TEACHERS_PLACEHOLDER')"
                                :field-name="'teachers'"
                                :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                                :callback="getSelectedTeacherIds"
                                :api-error-text="''"
                                :rules="''"
                                :inline-subtitle="$t('OPTIONAL')"
                                class="form-element"/>

        <p v-if="selectedSchool.id != null && !teacherOptions.length"
           class="page-section-text section-text">
          {{ $t('CREATE_CLASS.NO_TEACHERS') }}

        </p>
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t('CREATE_CLASS_TEXT') }}
        </button>
      </template>

    </FormWrapper>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import FormWrapper from '@/components/yo-form/FormWrapper'
import InputField from '@/components/yo-form/InputField'
import DropdownWithCheckboxes from '@/components/yo-form/DropdownWithCheckboxes'
import Dropdown from '@/components/yo-form/Dropdown'
import { useI18n } from 'vue-i18n'
import { computed, ref } from '@vue/reactivity'
import Search from '@/components/elements/selectSchools/Search'
import SearchResults from '@/components/elements/selectSchools/SearchResults'
import { SEARCH_SCHOOLS } from '@/store/modules/auth/actions'
import { HELPDESK_CREATE_CLASS } from '@/store/modules/helpdesk/actions'
import { RESET_SEARCHED_SCHOOLS } from '@/store/modules/auth/mutations'
import { GET_CLASS_TYPES_HELPDESK } from '@/store/actions'
import { useStore } from 'vuex'
import { watch } from '@vue/runtime-core'
import ApiHandler from '../../../utils/handlers/ApiHandler'
import { useRouter } from 'vue-router'
import InputClassName from '@/components/elements/inputFields/InputClassName'
import ToggleSwitch from '@/components/yo-form/ToggleSwitch'
import {SET_TOAST_DATA} from "@/store/mutations";

export default {
  name: 'ClassCreate',
  components: {
    FormWrapper,
    BackButton,
    PageTitle,
    InputField,
    DropdownWithCheckboxes,
    Dropdown,
    Search,
    SearchResults,
    InputClassName,
    ToggleSwitch
  },
  setup() {
    const { t } = useI18n({})
    const store = useStore()
    const router = useRouter()
    // Scope needs to be adjusted for this to work
    store.dispatch(GET_CLASS_TYPES_HELPDESK);
    const searchResults = computed(() => store.getters.getSearchedSchools.schools || [])

    const selectedSchool = ref({})

    const selectedTeachers = ref([])

    const teacherOptions = ref([])

    const isSpecialSchool = ref(false)

    const isCompetitionInfoShown = ref(false)

    const selectedGroup = ref({})

    const groups = computed(() => {
      const classTypes = store.getters.getClassTypes;
      if(isSpecialSchool.value) {
        return classTypes.filter(type => type.isSpecialEducationEligible);
      }
      return classTypes;
    });

    const noSchoolSelected = ref(null)   

    /** Accessability Type **/
    const accessibilityOptions = [
      { id: 1, name: t('ASSISTANCE.REGULAR') },
      { id: 2, name: t('ASSISTANCE.SIGN_LANGUAGE') },
      { id: 3, name: t('ASSISTANCE.AUDIO_DESCRIPTION') },
      { id: 4, name: t('ASSISTANCE.SUBTITLES') }]
    const selectedAccessibilityType = ref(accessibilityOptions[0])

    function accessibilityTypeUpdate(selected) {
      selectedAccessibilityType.value = selected
    }


    function getSelectedTeacherIds(teachers) {
      selectedTeachers.value = teachers
    }

    // TODO: These are pretty much the same with EditClass.vue. When the API is final, we could merge them in a mixin.
    /** Teachers Dropdown **/
    watch(() => selectedSchool.value,
      (curr, prev) => {
        if (curr.id) {
          ApiHandler.get(`helpdesk/school/get-school-details/${curr.id}`).then(response => {
            if (response) {              
              isSpecialSchool.value = response.data.isSpecialSchool
              teacherOptions.value = response.data.linkedTeachers
              selectedGroup.value = {}
            }
          })
        } else {
          selectedTeachers.value = []
          teacherOptions.value = []
          selectedGroup.value = {}
        }
      })


    /** Searching **/

    const schoolSearchValue = ref('')

    function manageSearch(data) {
      schoolSearchValue.value = data
      if (!data) {
        selectedSchool.value = {}
        clearSearch()
      } else {
        selectedSchool.value = {}
        const payload = {
          searchQuery: data,
          page: 1
        }
        searchSchool(payload)
      }
    }

    function searchSchool(payload) {
      return store.dispatch(SEARCH_SCHOOLS, payload)
    }

    function onItemClick(item) {
      noSchoolSelected.value = false
      selectedSchool.value = item
      schoolSearchValue.value = item.name
      clearSearch()
    }

    function clearSearch() {
      schoolSearchValue.value = ''
      //schoolSearchValue.value = ''
      // Reset the stored searched schools.
      store.commit(RESET_SEARCHED_SCHOOLS, undefined)
    }

    const hasExtraSounds = ref(true)
    function onSoundsToggle(newToggleVal) {
      hasExtraSounds.value = newToggleVal
    }

    /** Form **/
    function validateData(formData) {
      noSchoolSelected.value = false
      formData.classTypeId = selectedGroup.value.id
      formData.schoolId = selectedSchool.value.id
      formData.teachers = selectedTeachers.value.map(value => value.id)
      formData.accessibilityTypeId = selectedAccessibilityType.value.id
      if (!formData.schoolId) {
        noSchoolSelected.value = true
        return
      }
      store.dispatch(HELPDESK_CREATE_CLASS, formData).then(response => {
        if (response.status === 201) {
          store.commit(SET_TOAST_DATA, { text: `Schoolklas "${response.data.name}" succesvol aangepast`, src: require('@/assets/icons/icn_successful.svg') })
          router.push({ name: ROUTE_NAMES_HELPDESK.CLASSES_OVERVIEW })
        }
      })
    }

    const currentName = ref('')
    const nameAvailable = ref(true)
    function nameUpdated(value) {
      currentName.value = value
    }

    return {
      ROUTE_NAMES_HELPDESK,

      /** Teachers Dropdown **/
      teacherOptions,
      selectedTeachers,
      selectedSchool,
      getSelectedTeacherIds,
      schoolSearchValue,
      noSchoolSelected,

      groups,
      selectedGroup,
      isCompetitionInfoShown,
      searchResults,
      manageSearch,
      clearSearch,
      onItemClick,
      nameAvailable,
      nameUpdated,

      /** Accessibility Type **/
      accessibilityOptions,
      selectedAccessibilityType,
      accessibilityTypeUpdate,

      hasExtraSounds,
      onSoundsToggle,

      /** Group Dropdown **/
      validateData,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.students-group {
  margin-bottom: rem(28);
}

.no-school-selected{
  color: red;
  margin: rem(5) rem(0);
}

.toggles-container {
  margin-bottom: rem(32);
}

.divider {
  background-color: var(--purple_light);
  border: none;
  height: rem(2);
  opacity: 30%;
  margin: rem(40) 0;
}

.info-button-wrapper {
  position: absolute;
  right: rem(-35);
  top: 65%;
  transform: translateY(-50%);
  cursor: pointer;
}

.field-wrapper {
  position: relative;
}

.info-popup {
  position: absolute;
  top: rem(-22);
  right: rem(-440);
  width: rem(380);
  padding: rem(16);
  border-radius: rem(8);
  background: white;
  color: var(--blue_dark-03);
  box-shadow: rem(2) rem(2) 0 0 var(--gray-light_04);
  opacity: 0;
  transition: opacity 0.2s;

  &.shown {
    opacity: 1;
  }
}

.triangle {
  position: absolute;
  left: rem(-14);
  top: 50%;
  height: 0;
  border-left: rem(8) solid transparent;
  border-right: rem(8) solid transparent;
  border-bottom: rem(16) solid white;
  transform: rotate(-90deg) translateX(50%);
}
</style>
